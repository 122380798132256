<template>
<div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

    <v-dialog v-if="modalParcial" v-model="modalParcial" width="400">
        <div>
            <s-toolbar close save @save="save()" :label="'Atencion Parcial'" @close="modalParcial=false" />
            <v-card>
                <v-col>
                    <v-row>
                        <v-col lg="12" cols="12">
                            <b>Esta recibiendo una cantidad inferior a la solicitada. ¿Que desea hacer?</b>
                        </v-col>
                        <v-col lg="12" cols="12" style="margin-top:-30px">
                            <!--  <v-col class="pr-0 pl-0"> -->
                            <v-radio-group v-model="rbtnState" column>
                                <v-radio label="Parcialmente atendido" color="red" value="7" checked=""></v-radio>
                                <v-radio label="Cerrar Orden" color="red darken-3" value="5"></v-radio>
                            </v-radio-group>
                            <!-- </v-col> -->
                        </v-col>

                    </v-row>
                </v-col>
            </v-card>
        </div>
    </v-dialog>

    <div>
        <s-toolbar close :save="value == null || orderLocal.OrdStatus == 1 || (orderLocal.OrdStatus == 2 && orderLocal.OrdStatusReception == 7)" @save="saveTmp()" :label="orderLocal.TypeOrder == 1 ? 'Orden de Compra Nro. ' + orderLocal.OrdDocumentNumber + ' - ' + orderLocal.NtpFullName : 'Orden de Servicio Nro. ' + orderLocal.OrdDocumentNumber + ' - ' + orderLocal.NtpFullName" @close="$emit('onClose')" />
        <v-card>
            <v-col>
                <v-row>
                    <v-col lg="3" cols="12">
                        <v-card elevation="0" height="auto">
                            <v-col class="">

                                <v-row>
                                    <v-col class="s-col-text" cols="12" lg="12">
                                        <v-supplier style="font-family: Calibri" :disabled="!isEditable" label="Proveedor" v-model="Supplier" />
                                    </v-col>
                                    <v-col lg="6" md="6" cols="12" class="s-col-text">
                                        <s-text style="font-family: Calibri" label="Contacto" :disabled="!isEditable" v-model="ConName"></s-text>
                                    </v-col>

                                    <v-col lg="6" md="6" cols="12" class="s-col-text">
                                        <s-text style="font-family: Calibri" label="Telefono" :disabled="!isEditable" v-model="ConTelephone"></s-text>
                                    </v-col>

                                    <v-col lg="12" cols="12" class="s-col-text">
                                        <s-text style="font-family: Calibri" label="Email" :disabled="!isEditable" v-model="ConEmail"></s-text>
                                    </v-col>

                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-select-definition style="font-family: Calibri" :disabled="!isEditable" :def="1129" label="Moneda" v-model="orderLocal.TypeCurrency" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-select-definition style="font-family: Calibri" :disabled="!isEditable" :def="1159" label="Tipo Credito" v-model="orderLocal.TypeCredito" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-select-definition style="font-family: Calibri" :disabled="!isEditable" :def="1170" label="Forma de Pago" v-model="orderLocal.TypeFormaPago" />
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-text label="Tiempo de entrega" v-model="orderLocal.OrdDeliveryTime" disabled></s-text>
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-text label="Garantia" v-model="orderLocal.OrdTimeGarantia" disabled></s-text>
                                    </v-col>
                                    <v-col lg="12" class="s-col-text" cols="6">
                                        <s-date label="Fecha" style="font-family: Calibri" v-model="dateOrder" :disabled="!isEditable"></s-date>
                                    </v-col>
                                </v-row>

                                <v-divider style="margin-top:20px" horizontal></v-divider>
                                 <v-divider style="margin-top:0px" horizontal></v-divider>
                                 <v-divider style="margin-top:0px" horizontal></v-divider>

                                <v-row style="margin-top:10px; margin-bottom: 10px">
                                    <v-col lg="12" cols="12" class="s-col-text"><b style="font-family:Calibri">Adjuntos</b>
                                        <v-btn v-if="!isEditable" class="ml-2" text color="info" small style="text-transform: initial;" @click="uploadFile()">Subir Archivos
                                            <v-icon> mdi-arrow-up</v-icon>
                                        </v-btn>
                                    </v-col>

                                    <v-col lg="12" cols="12" class="s-col-text">
                                        <!-- v-if="orderLocal.OrdStatus !== 5" -->

                                        <v-row>
                                            <v-col cols="12" v-for="(item, index) in itemAttach" :key="item.RqdID">
                                                <v-file-input show-size counter :ref="'attach'+item.Id" :accept="item.RqdAccept" :label="item.RqdDescription" v-model="item.RqdAttach">
                                                    <template slot="append" v-if="item.RqdRequired == 1">
                                                        <label style="color:red">(*)</label>
                                                    </template>
                                                </v-file-input>
                                            </v-col>
                                        </v-row>

                                    </v-col>

                                    <v-col lg="12" md="12" cols="12" class="s-col-text">
                                        <v-card outlined>
                                            <v-data-table class="elevation-0 scroll table table-hover table-condensed" :items-per-page="-1" disable-sort hide-default-footer dense :headers="headersFiles" :items="orderLocal.itemsAttachReceived">
                                                <template v-slot:item.RqdAttach="{ item }">
                                                    <v-icon color="green" @click="openFile(item.Attach)">mdi-download</v-icon>
                                                    {{item.RqdAttach}}
                                                </template>

                                            </v-data-table>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-card>
                    </v-col>

                    <v-divider vertical></v-divider>

                    <v-col lg="9" cols="12">
                        <v-col class="pr-0 pl-0">
                            <v-row>
                                <v-col cols="4">
                                    <s-date :disabled="orderLocal.OrdStatus == 2" cols="6" lg="4" md="4" label="Fecha Atencion" v-model="orderLocal.OrdAttentionDate"></s-date>
                                </v-col>

                                <v-col cols="4" v-if="orderLocal.TypeOrder == 1">
                                    <s-text label="Nª Guia de remision" v-if="orderLocal.OrdStatus == 1  || orderLocal.OrdStatus == 2" v-model="orderLocal.OrdGuideRemission" ref="OrdGuideRemission" :mask="FormatGuideRemission"></s-text>
                                    <s-text label="Nª Guia de remision" v-if="orderLocal.OrdStatus == 5" v-model="orderLocal.OrdGuideRemission" ref="OrdGuideRemission"></s-text>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-card outlined width="100%" style="margin-top:10px">
                                    <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">Sustentos Obligatorios
                                        <span v-if="messageSustentos && !isEditable">
                                            -
                                            <b style="color: red">Esta OC no contiene sustentos registrados.</b></span>
                                    </v-card-title>
                                </v-card>

                                <v-col lg="3">
                                    <b>Obligatorios</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" :disabled="!isEditable" v-model="SupportRequired.SreCompPayment" label="Comp. de Pago" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreXml" label="XML" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreCdr" label="CDR" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreOcos" label="OC/OS" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -10px;" :disabled="!isEditable" v-model="SupportRequired.SreQuotation" label="Cotización" color="red" hide-details></v-checkbox>
                                </v-col>
                                <v-col lg="3">
                                    <b>Obra</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" :disabled="!isEditable" v-model="SupportRequired.SreContracts" label="Contratos" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreAddenda" label="Adendas" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreValuations" label="Valorizaciones" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreRecordConformity" label="Acta de Conformidad" color="red" hide-details></v-checkbox>
                                </v-col>

                                <v-col lg="2">
                                    <b>Servicios</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" :disabled="!isEditable" v-model="SupportRequired.SreContracts_1" label="Contratos" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreAddenda_1" label="Adendas" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreReports" label="Informes" color="red" hide-details></v-checkbox>
                                </v-col>

                                <v-col lg="2">
                                    <b>Bienes y Productos</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" :disabled="!isEditable" v-model="SupportRequired.SreGuideRemission" label="Guia de Remision" color="red" hide-details></v-checkbox>
                                </v-col>

                                <v-col lg="2">
                                    <b>Demas Sustentos</b>
                                    <v-checkbox style="margin-bottom: -30px; margin-top: -10px" :disabled="!isEditable" v-model="SupportRequired.SrePhotos" label="Fotos" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreLiquidations" label="Liquidaciones" color="red" hide-details></v-checkbox>
                                    <v-checkbox style="margin-bottom: -30px;" :disabled="!isEditable" v-model="SupportRequired.SreRecordDelivery" label="Acta de entrega" color="red" hide-details></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-row>
                            <v-col>
                                <v-data-table style="font-family:" dense @click:row="rowClick" :headers="headersSAP" :items-per-page="-1" v-model="selectedSAP" :items="itemsSAP" item-key="Line" :height="$vuetify.breakpoint.xs ? 'auto' : '200'" hide-default-footer>
                                    <template v-slot:item.DerQuantityServed="props">
                                        <s-text v-if="orderLocal.OrdStatus == 1  || (orderLocal.OrdStatus == 2 && orderLocal.OrdStatusReception == 7)" style="width: 125px" decimal v-model="props.item.DerQuantityServed">
                                            Cantidad Atendida
                                        </s-text>
                                        <div v-else>
                                            {{props.item.DerQuantityServed}}
                                        </div>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-col style="padding-top: 24px">
                            <v-row>
                                <v-col lg="12" cols="12">
                                    <v-row>
                                        <v-col class="s-col-text">
                                            <v-textarea auto-grow label="Observaciones Generales" v-model="orderLocal.OrdObservation" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-col>

                </v-row>
            </v-col>
        </v-card>
    </div>
</div>
</template>

<script>
//Services
import _sRequirement from "@/services/Logistics/RequirementService";
import _sRequirementConfig from "@/services/Logistics/LgsRequirementconfig";
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseService";
import _sApprovalLevel from "@/services/Security/ApprovalLevelsService";
import _sGeneral from "@/services/HelperService.js";
import _sParam from "@/services/General/ParameterService.js";

//Components
import vSupplier from '@/views/Logistics/vSupplier.vue'

export default {
    components: {
        vSupplier,
    },
    props: {
        order: {},
        value: null,
        viewLoad: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({
        processing: false,
        messageProcessing: "",
        modalParcial: false,
        rbtnState: null,
        messageSustentos: false,
        dialogTax: false,
        selectedTax: false,
        selectedrow: [],
        dateOrder: null,
        selectedSAP: [],
        tax: null,
        orderLocal: {
            TypeCurrency: 2
        },
        dialogAdd: false,
        itemsConcept: [],
        itemsAccount: [],
        ConName: null,
        ConTelephone: null,
        ConEmail: null,
        SupID: "",
        Supplier: null,
        itemsSAP: [],
        itemsRemove: [],
        headersSAP: [{
                text: "Descripción",
                value: "OddDescription",
                sortable: false,
                width: 540,
            },
            {
                text: "Prc. U.",
                value: "OddUnitPrice",
                sortable: false,
                width: 90
            },
            {
                text: "Cant. Solic.",
                value: "DerQuantity",
                sortable: false,
                width: 50
            },
            {
                text: "Cant. recibida",
                value: "DerQuantityAttended",
                sortable: false,
                width: 50
            },

            {
                text: "Cant.",
                value: "DerQuantityServed",
                sortable: false,
                width: 50
            },

            {
                text: "Und",
                value: "UnitMeasurement",
                sortable: false,
                width: 50
            },

            {
                text: "Comentario",
                value: "OddObservation",
                sortable: false,
                width: 400,
            },
        ],

        SupportRequired: {
            SreCompPayment: true,
            SreXml: true,
            SreCdr: true,
            SreOcos: true,
            SreQuotation: true,
            SreContracts: false,
            SreAddenda: false,
            SreValuations: false,
            SreRecordConformity: false,
            SreContracts_1: false,
            SreAddenda_1: false,
            SreReports: false,
            SreGuideRemission: false,
            SrePhotos: false,
            SreLiquidations: false,
            SreRecordDelivery: false

        },

        FormatGuideRemission: "###-####",
        itemAttach: [],
        headersFiles: [{
            text: "Archivo",
            value: "RqdDescription"
        },{
            text: "Archivo",
            value: "RqdAttach"
        }, {
            text: "Fec. C.",
            value: "SecCreate"
        }]
    }),
    computed: {
        isEditable() {
            return (
                (this.orderLocal.OrdStatus == 3 &&
                    this.$fun.getUserID() == this.orderLocal.UsrCreateID) ||
                this.orderLocal.OrdID == null
            );
        },
    },

    created() {

        if (this.value != null) {
            this.initialize();
        } else {

            //this.orderLocal.TypeOrder = this.typeOrder;
            this.itemsSAP = this.order.detail;
            if (this.itemsSAP)
                for (let i = 0; i < this.itemsSAP.length; i++)
                    this.itemsSAP[i].Line = i + 1;
        }

        

    },

    mounted() {

        if (this.value != null) {
            this.processing = true;
        }

        _sParam.search({
            PrmName: "FormatoGuiaRemision"
        }, this.$fun.getUserID()).then(resp => {
            if (resp.status == 200) {
                this.FormatGuideRemission = resp.data.PrmValue
            }
        })

        
    },

    watch: {

        Supplier() {
            if (this.Supplier != null && this.isEditable)
                if (this.Supplier.SupID != null) {
                    this.SupID = this.Supplier.SupID;
                    this.ConName = this.Supplier.ConName
                    this.ConTelephone = this.Supplier.ConTelephone
                    this.ConEmail = this.Supplier.ConEmail

                }
        },
        isEditable() {
            this.processing = false;
        },
    },

    methods: {

        initialize() {
            _sOrderPurchase
                .byID({
                    OrdID: this.value
                }, this.$fun.getUserID())
                .then((r) => {

                    r.data.itemsAttachReceived.forEach(element => {
                        element.Attach = {
                            name: element.RqdAttach
                        };
                        element.SecCreate = this.$moment(element.SecCreate).format(this.$const.FormatDateTimeDB);
                    });

                    this.orderLocal = r.data;
                    let sup = {
                        SupID: this.orderLocal.SupCode,
                        SupName: this.orderLocal.SupName,
                    };

                    this.dateOrder = this.orderLocal.OrdDate;
                    this.ConName = this.orderLocal.ConName
                    this.ConTelephone = this.orderLocal.ConTelephone
                    this.ConEmail = this.orderLocal.ConEmail

                    this.Supplier = sup;
                    this.OrdStatus = r.data.OrdStatus;
                    this.itemsSAP = r.data.Detail;

                    if (r.data.itemsSupport !== null) {
                        this.SupportRequired = r.data.itemsSupport;
                        this.messageSustentos = false;
                    } else {
                        this.messageSustentos = true;
                    }

                    this.getConfigFiles()

                });
        },

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 1).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        getConfigFiles() {
            _sRequirementConfig.list(3, this.$fun.getUserID()).then(resp => {
                this.itemAttach = resp.data
                console.log(this.itemAttach)
                this.itemAttach.map((x) => {
                    if (this.orderLocal.TypeOrder == 1) {
                        x.RqdRequired = 0
                    }
                    return x;
                })
                
            })
        },

        //***************************************** Item Detalle ******************************************************************** */

        //Seleccionar Fila
        rowClick: function (item, row) {
            this.selectedSAP = [item];
        },

        /***************************************** Fin Item Detalle ***************************************************************** */

        isVerificated() {

            var isValid = false
            var sumTotal = 0, sumRestant = 0
            for (let i = 0; i < this.itemsSAP.length; i++) {

                var restante = 0,
                    restante2 = 0;

                restante = (this.itemsSAP[i].DerQuantity - this.itemsSAP[i].DerQuantityAttended)
                restante2 = restante - this.itemsSAP[i].DerQuantityServed

                sumTotal = sumTotal + restante
                sumRestant = sumRestant + restante2

                

                if (restante2 > 0) {
                    isValid = true
                }

                /* if (
                    this.itemsSAP[i].DerQuantityServed == 0
                ) {
                    isValid = false;
                } */
            }

            console.log(sumTotal, sumRestant)

            return isValid
        },

        saveTmp() {
            if (this.isVerificated()) {
                this.modalParcial = true
            } else {
                this.save()
            }
        },

        save() {

            console.log(this.itemAttach)

            if (this.modalParcial) {
                if (this.rbtnState == null) {
                    this.$fun.alert("Seleccione opcion", "warning")
                    return
                }
            }

            if (this.orderLocal.OrdAttentionDate == null) {
                this.$fun.alert("Seleccione Fecha de atención", "warning")
                return
            }
            if (this.$moment(this.orderLocal.OrdAttentionDate).format(this.$const.FormatDateDB) < this.$moment(this.orderLocal.OrdDate).format(this.$const.FormatDateDB)) {
                this.$fun.alert("Error, fecha de atención no puede ser menor que fecha de OC", "warning")
                return
            }

            if (this.$moment(this.orderLocal.OrdAttentionDate).format(this.$const.FormatDateDB) > this.$fun.getDate()) {
                this.$fun.alert("Error, fecha de atención no puede superar fecha actual: " + this.$fun.getDate(), "warning")
                return
            }

            if (this.orderLocal.TypeOrder == 1) {

                if (this.orderLocal.OrdGuideRemission == null || this.orderLocal.OrdGuideRemission == "") {
                    this.$refs.OrdGuideRemission.error("Ingrese Nº Guia de Remision")
                    this.$refs.OrdGuideRemission.focus();
                    return
                }
            }

            this.order.detail = [];
            for (let i = 0; i < this.itemsSAP.length; i++) {
                if (
                    this.itemsSAP[i].DerQuantityServed == null
                ) {
                    /* this.$fun.alert(
                        "Cantidad recibida inválido, " + this.itemsSAP[i].OddDescription,
                        "warning"
                    );
                    return; */
                    this.itemsSAP[i].DerQuantityServed = 0
                }

                if (
                    this.itemsSAP[i].DerQuantity < this.itemsSAP[i].DerQuantityServed
                ) {
                    this.$fun.alert(
                        "Cantidad recibida es mayor a cantidad solicitada, " + this.itemsSAP[i].OddDescription,
                        "warning"
                    );
                    return;
                }

               /*  if (
                    this.itemsSAP[i].DerQuantityServed == 0 && (this.itemsSAP[i].DerQuantityAttended < this.itemsSAP[i].DerQuantity)
                ) {
                    this.$fun.alert(
                        "Cantidad recibida incorrecta, " + this.itemsSAP[i].OddDescription,
                        "warning"
                    );
                    return;
                } */

                if (this.itemsSAP[i].DerQuantityServed > (this.itemsSAP[i].DerQuantity - this.itemsSAP[i].DerQuantityAttended)) {
                    this.$fun.alert(
                        "Cantidad recibida es mayor a cantidad solicitada, cantidad restante : " + (this.itemsSAP[i].DerQuantity - this.itemsSAP[i].DerQuantityAttended) + ", " + this.itemsSAP[i].OddDescription,
                        "warning"
                    );
                    return;
                }

                this.itemsSAP[i].UsrUpdateID = this.$fun.getUserID();
            }

            if (this.$fun.isValidExtension(this.itemAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemAttach)) {
                return
            }

            this.orderLocal.Detail = this.itemsSAP;

            console.log("save", this.orderLocal)

            this.$fun.alert("Seguro de guardar?", "question", false).then((r) => {
                this.$emit("clearItems");
                if (r.value) {
                    this.orderLocal.OrdStatus = this.rbtnState == null ? 5 : this.rbtnState == 7 ? 2 : this.rbtnState
                    this.orderLocal.OrdStatusReception = this.rbtnState == null ? 2 : this.rbtnState == 5 ? 7 : this.rbtnState
                    this.messageProcessing = ""
                    this.processing = true

                    //Inicio Cargar archivos en servidor
                    let files = [];

                    for (let index = 0; index < this.itemAttach.length; index++) {
                        if (this.itemAttach[index].RqdAttach !== null) {
                            files.push({
                                RqdID: this.itemAttach[index].RqdID,
                                RqdAttach: this.itemAttach[index].RqdAttach
                            })
                        }
                    }

                    var formData = new FormData();
                    files.forEach((element) => {
                        formData.append(element.RqdID, element.RqdAttach);
                    });

                    _sGeneral.uploadfilelogistics("123", this.$fun.getUserID(), formData, 1).then(resp => {
                        if (resp.status == 200) {
                            this.orderLocal.itemsAttach = resp.data

                            _sOrderPurchase
                                .saveServed(this.orderLocal, this.$fun.getUserID())
                                .then((r) => {
                                        this.processing = false
                                        this.$fun.alert("Guardado Correctamente", "success");
                                        this.$emit("close");
                                        this.$emit("onCloseClear");
                                        this.$emit("onClose");

                                    },
                                    (e) => {
                                        this.messageProcessing = e.response.data.Message;
                                        this.processing = false;
                                    });

                        }
                    })

                }
            });
        },

        uploadFile() {
            if (this.$fun.isValidExtension(this.itemAttach)) {
                return
            }

            if (this.$fun.isValidTamanio(this.itemAttach)) {
                return
            }

            //Inicio Cargar archivos en servidor
            let files = [];

            for (let index = 0; index < this.itemAttach.length; index++) {
                if (this.itemAttach[index].RqdAttach !== null) {
                    files.push({
                        RqdID: this.itemAttach[index].RqdID,
                        RqdAttach: this.itemAttach[index].RqdAttach
                    })
                }
            }

            if (files.length == 0) {
                this.$fun.alert("No existen archivos para subir", "warning")
                return
            }

            var formData = new FormData();
            files.forEach((element) => {
                formData.append(element.RqdID, element.RqdAttach);
            });

            this.$fun.alert("¿Seguro de subir Archivos?", "question").then(val => {
                if (val.value) {
                    this.messageProcessing = ""
                    this.processing = true

                    _sGeneral.uploadfilelogistics("123", this.$fun.getUserID(), formData, 1).then(resp => {
                            if (resp.status == 200) {
                                this.orderLocal.itemsAttach = resp.data

                                this.orderLocal.itemsAttach.forEach(element => {
                                    element.OrdID = this.orderLocal.OrdID
                                    element.UsrCreateID = this.$fun.getUserID()
                                });

                                if (this.orderLocal.itemsAttach.length > 0) {
                                    _sOrderPurchase.savefiles(this.orderLocal.itemsAttach, this.$fun.getUserID()).then((r) => {
                                            if (r.status == 200) {
                                                this.initialize();
                                                this.processing = false;
                                            }
                                        },
                                        (e) => {
                                            this.messageProcessing = e.response.data.Message;
                                            this.processing = false;
                                        });
                                }
                            }
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = false;
                        });
                }
            })

        },
    }
};
</script>
