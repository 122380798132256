<template>
<div style="padding-top:-2px;margin-top:-2px;">
    <!-- <v-dialog width="50%" v-model="dialog" v-if=dialog permanent>

        <v-provider @close='dialog=false' @load=load($event)></v-provider>
    </v-dialog> -->
    <v-label><b>{{label}}</b></v-label>
    <s-select-generic 
        autocomplete 
        class="text" 
        itemtext="SupName" 
        itemvalue="SupID" 
        :disabled="disabled"
        id="SupName" 
        :value="value" 
        :return-object="returnObject"
        @input="input($event)" 
        :config="config" 
        :full="full" 
        :autofocus="autofocus"
        :text="text" 
        :clearable="clearable"
        :noDefault="noDefault" 
        :add="add"
        @add="addEvent()"
        ref="selectProvider">
    </s-select-generic>
</div>
</template>

<script>
/* import vProvider from '@/views/Logistics/Provider/ProviderEdit' */
import _sGenericService from "@/services/Logistics/SupplierService";
export default {
    components: {
        /* vProvider */
    },
    props: {
        autofocus: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        value: null,
        text: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "Proovedor",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        noDefault: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        add: {
            type: Boolean,
            default: false,
        },
    },
    watch: {

        value(value) {
            this.item = value;
        },
    },
    methods: {
        load(item) {
            this.text = item.SupName
        },
        addEvent() {
            this.dialog = true;
        },
        input(val) {
            this.$emit("input", val);
        },

    },
    data: () => ({
        dialog: false,
        item: 0,
        val: '',
        items: [],
        config: {},
    }),

    created() {
        this.config = {
            url: _sGenericService.getSupplierSearch(),
            params: {
                search: '',
                requestID: this.$fun.getUserID()
            }
        }
    } //75277693
}
</script>
